import React, { CSSProperties } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Home, Contact, FAQ, Privacy, Rules, Terms } from './pages';
import { Header, Footer } from './components';

interface AppState {
  selectedPage: String
}

const appStyle: CSSProperties = {
  height: "100vh"
}

class App extends React.Component<{}, AppState> {
  constructor(props: Object) {
    super(props);
    this.state = {
      selectedPage: ""
    };
    this.select = this.select.bind(this);
  }

  select(event: String): void {
    console.log("APP event: ", event)
    this.setState({selectedPage: event});
  }

  render() {
    const {selectedPage} = this.state;
    // return (
    //   <div style={appStyle}>
    //     <Header select={this.select}/>
    //     {(() => {
    //           if (selectedPage === "") {
    //             return (
    //               <Home/>
    //             )
    //           } else if (selectedPage === "terms-of-use") {
    //             return (
    //               <Terms/>
    //             )
    //           } else if (selectedPage === "faq") {
    //             return (
    //               <FAQ/>
    //             )
    //           } else if (selectedPage === "contact") {
    //             return (
    //               <Contact/>
    //             )
    //           } else if (selectedPage === "privacy-policy") {
    //             return (
    //               <Privacy/>
    //             )
    //           } else if (selectedPage === "official-rules") {
    //             return (
    //               <Rules/>
    //             )
    //           } else {
    //             return (
    //               <div>Page not found</div>
    //             )
    //           }
    //     })()}
    //     <Footer select={this.select} />
    //   </div>
    // );

    return (
      <Router>
        <div>
          <Header select={this.select}/>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/rules">
              <Rules />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/faq">
              <FAQ />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Footer select={this.select} />
        </div>
      </Router>
    );
  }

}

export default App;
