import React, { CSSProperties } from "react";
import styled from 'styled-components';
import {
    BrowserRouter as Router,
    Link,
  } from "react-router-dom";

const containerStyle: CSSProperties = {
    backgroundColor: "#4B648A",
    padding: "20px",
    fontFamily: "Avenir black",
    color: "white",
    fontSize: "12px",
    display: "flex",
    flexDirection: "column"
}
const linkBoxStyle: CSSProperties = {
    width: '100%',
    display: "flex",
    justifyContent: "center",
    flexFlow: "row wrap"
}
const linkStyle: CSSProperties = {
    padding: "20px",
    cursor: "pointer"
}
const logoBoxStyle: CSSProperties = {
    width: '100%',
    display: "flex",
    justifyContent: "center"
}
const logoStyle: CSSProperties = {
    width: '60px',
    height: '70px'
}

interface FooterProps {
    select: Function
}

const Footer: React.FC<FooterProps> = (props) => {
    return(
        <div style={containerStyle}>
            <div style={linkBoxStyle}>
                <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}><div style={linkStyle}>CONTACT</div></Link>
                <Link to="/terms" style={{ textDecoration: 'none', color: 'inherit' }}><div style={linkStyle}>TERMS OF USE</div></Link>
                <Link to="/faq" style={{ textDecoration: 'none', color: 'inherit' }}><div style={linkStyle}>FAQ</div></Link>
                <Link to="/rules" style={{ textDecoration: 'none', color: 'inherit' }}><div style={linkStyle}>OFFICIAL RULES</div></Link>
                <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}><div style={linkStyle}>PRIVACY POLICY</div></Link>
            </div>
            <div style={logoBoxStyle}>
                <img style={logoStyle} src="https://cdn.batteruptech.com/static/bu_logo_white_3.svg"/>
            </div>
        </div>
    );
};

export { Footer };