import React, { CSSProperties } from "react";
import {
    BrowserRouter as Router,
    Link,
  } from "react-router-dom";
import styled from 'styled-components';

const ContentContainer = styled.div`
    height: 70px;
    background-color: #8A231C;
    display: flex;
    padding: 20px;
`;

const logoStyle: CSSProperties = {
    width: '60px',
    height: '70px'
}

interface HeaderProps {
    select: Function
}

const Header: React.FC<HeaderProps> = (props) => {
    const { select } = props;
    return(
        <div>
            <ContentContainer>
                <Link to="/"><img style={logoStyle} src="https://cdn.batteruptech.com/static/bu_logo_white_3.svg"/></Link>
            </ContentContainer>
        </div>
    );
};

export { Header };