import React, { CSSProperties } from "react";
import styled from 'styled-components';

const backgroundStyle: CSSProperties = {
    background: 'url(https://cdn.batteruptech.com/static/bg_large.png)',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    textAlign: "left",
    padding: "25px",
    display: "flex",
    justifyContent: "space-evenly",
    flexFlow: "row wrap"
}
const appStoreButtonStyle: CSSProperties = {
    width: '150px'
}
const appExampleStyle: CSSProperties = {
    width: '500px',
    maxWidth: '100%'
}
const messageBox: CSSProperties = {
    width: '362px',
    padding: '15px'
}
const imageBox: CSSProperties = {
    width: '500px',
    padding: '15px',
    display: "flex",
    alignItems: "center",
    flexFlow: "column"
}
const teamBox: CSSProperties = {
    width: "100%",
    padding: "20px 0"
}
const teamBoxTitle: CSSProperties = {
    fontFamily: "Avenir next condensed",
    color: "#8A231C",
    fontSize: "42px",
    fontWeight: "bold",
    textAlign: "center"
}
const teammateBox: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexFlow: "row wrap"
}
const teammateCard: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    padding: "20px"
}
const teamPictureBox: CSSProperties = {
    // height: "200px",
    // width: "200px",
    // overflow: "hidden"
}
const teamPictureStyle: CSSProperties = {
    height: "200px",
    width: "200px",
    objectFit: "contain"
}
const teammateDescription: CSSProperties = {
    fontFamily: "Avenir next condensed",
    backgroundColor: "#4B648A",
    color: "white",
    fontSize: "20px",
    padding: "5px",
    width: "190px"
}
const teammateName: CSSProperties = {
    fontWeight: "bold"
}
const teammateTitle: CSSProperties = {
    padding: "5px 0"
}
const boldTextStyle: CSSProperties = {
    fontFamily: "Avenir next condensed",
    color: "white",
    fontSize: "42px",
    fontWeight: "bold",
    lineHeight: "40px"
}
const infoTextStyle: CSSProperties = {
    fontFamily: "Avenir black",
    color: "white",
    fontSize: "20px"
}
const actionTextStyle: CSSProperties = {
    fontFamily: "Avenir black",
    color: "white",
    fontSize: "20px"
}

const Home = () => {
    const boldText1 = "Increasing the love of baseball, one at bat at a time.".toUpperCase();
    const boldText2 = "A real time MLB Fantasy Sports app.".toUpperCase();
    const infoText = "Batter Up is a free-to-play gaming app that lets you compete with friends by predicting on the outcome of every at-bat in real time for all 9 innings of a baseball game.".toUpperCase();
    const actionText = "Click on the App Store link (or search for Batter Up! in your app store) to download the app and start playing.".toUpperCase();

    return (
        <div>
            <div style={backgroundStyle}>
                <div style={messageBox}>
                    <div style={boldTextStyle}>{boldText1}</div>
                    <div style={boldTextStyle}>{boldText2}</div>
                    <br/>
                    <div style={infoTextStyle}>{infoText}</div>
                    <br/>
                    <div style={actionTextStyle}>{actionText}</div>
                </div>

                <div style={imageBox}>
                    <img style={appExampleStyle} src="https://cdn.batteruptech.com/static/phones_2.png"/>
                    <br/>
                    <a href="https://apps.apple.com/us/app/batter-up/id1542716340"><img style={appStoreButtonStyle} src="https://cdn.batteruptech.com/static/ios-app-store-button.png"/></a>
                </div>
            </div>

            <div style={teamBox}>
                <div style={teamBoxTitle}>MEET THE TEAM</div>
                <div style={teammateBox}>
                    <div style={teammateCard}>
                        <div style={teamPictureBox}><img style={teamPictureStyle} src="https://cdn.batteruptech.com/static/headshots/erik.jpg"/></div>
                        <div style={teammateDescription}>
                            <div style={teammateName}>ERIK SCHWEISS</div>
                            <div style={teammateTitle}>CEO</div>
                            <div>Erik is responsible for Big Leagues Inc.'s operations and developing reach in the market.</div>
                        </div>
                    </div>
                    <div style={teammateCard}>
                        <div style={teamPictureBox}><img style={teamPictureStyle} src="https://cdn.batteruptech.com/static/headshots/mike.jpg"/></div>
                        <div style={teammateDescription}>
                            <div style={teammateName}>MIKE LINCOLN</div>
                            <div style={teammateTitle}>CFO</div>
                            <div>As Chief Financial Officer, Mike oversees the company's financial operations, planning, and investor relations.</div>
                        </div>
                    </div>
                    <div style={teammateCard}>
                        <div style={teamPictureBox}><img style={teamPictureStyle} src="https://cdn.batteruptech.com/static/headshots/matty.jpg"/></div>
                        <div style={teammateDescription}>
                            <div style={teammateName}>MATTHEW LANE</div>
                            <div style={teammateTitle}>CPO</div>
                            <div>Matthew leads the company's global product vision, roadmap, and development strategies.</div>
                        </div>
                    </div>
                    <div style={teammateCard}>
                        <div style={teamPictureBox}><img style={teamPictureStyle} src="https://cdn.batteruptech.com/static/headshots/gar.jpg"/></div>
                        <div style={teammateDescription}>
                            <div style={teammateName}>GAR LEE</div>
                            <div style={teammateTitle}>CTO</div>
                            <div>Gar is responsible for building and scaling the company's technologies, databases, and IT security.</div>
                        </div>
                    </div>
                    <div style={teammateCard}>
                        <div style={teamPictureBox}><img style={teamPictureStyle} src="https://cdn.batteruptech.com/static/headshots/nick.jpg"/></div>
                        <div style={teammateDescription}>
                            <div style={teammateName}>NICHOLAS PESA</div>
                            <div style={teammateTitle}>CIO</div>
                            <div>As Chief Information Officer, Nick leads the company's platform and mobile development.</div>
                        </div>
                    </div>
                    {/* <div style={teammateCard}>
                        <div style={teamPictureBox}><img style={teamPictureStyle} src="https://cdn.batteruptech.com/static/headshots/jarhett.jpg"/></div>
                        <div style={teammateDescription}>
                            <div style={teammateName}>JARHETT BLONIEN</div>
                            <div style={teammateTitle}>VP LEGAL</div>
                            <div>Jarhett is the company's in-house attorney and General Counsel.</div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export { Home };