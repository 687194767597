import React, { CSSProperties } from "react";

const backgroundStyle: CSSProperties = {
    backgroundColor: "#4B648A",
    padding: "20px 10%",
    fontFamily: "Avenir black",
    color: "white",
    fontSize: "12px",
    minHeight: "60%"
}
const titleStyle: CSSProperties = {
    fontSize: "42px",
    fontWeight: "bold",
    lineHeight: "40px",
    marginBottom: "20px"
}
const headerStyle: CSSProperties = {
    padding: "5px 0",
    fontSize: "30px",
    fontWeight: "bold"
}
const lineStyle: CSSProperties = {
    padding: "5px 0",
    fontSize: "15px",
    fontWeight: "normal"
}

const Contact = () => {
    return (
        <div style={backgroundStyle}>
            <div style={titleStyle}>CONTACT</div>
            <div>
                <div style={headerStyle}>EMAIL</div>
                <div style={lineStyle}>support@bigleaguesinc.com</div>
            </div>

            <div>
                <div style={headerStyle}>ADDRESS</div>
                <div style={lineStyle}>Big Leagues Inc.</div>
                <div style={lineStyle}>69 Lincoln Blvd Ste A-163</div>
                <div style={lineStyle}>Lincoln, CA, 95648-6303</div>
            </div>
        </div>
    );
};

export { Contact };