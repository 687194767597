import React, { CSSProperties } from "react";

const backgroundStyle: CSSProperties = {
    backgroundColor: "#4B648A",
    padding: "20px 10%",
    fontFamily: "Avenir black",
    color: "white",
    fontSize: "12px"
}
const titleStyle: CSSProperties = {
    fontSize: "42px",
    fontWeight: "bold",
    lineHeight: "40px"
}
const headerStyle: CSSProperties = {
    padding: "5px 0"
}
const lineStyle: CSSProperties = {
    padding: "5px 0"
}

interface DataState {
    updated: String,
    content: any
}

class Terms extends React.Component<{}, DataState> {
    constructor(props: any) {
        super(props)
        this.state = {
            updated: "",
            content: []
        }

        this.init = this.init.bind(this);
        this.init();
    }

    init() {
        const targetUrl = 'https://cdn.batteruptech.com/copy/terms_of_use.json'
        fetch(targetUrl)
        .then(res => res.json())
        .then((data) => {
            this.setState({updated: data.updated, content: data.content})
        })
        .catch((err) => {
            console.error("Error: ", err);
        })
    }

    render() {
        const { updated, content } = this.state;
        return (
            <div style={backgroundStyle}>
                <div style={titleStyle}>TERMS OF USE</div>
                <div>Last updated: {updated}</div>
                <div>
                    <div>
                        {content.map((item: any) => (
                            <div style={headerStyle} key={item.header}>
                                {item.header}
                                {item.body.map((line: any) => (
                                <div>
                                    {line.text}
                                </div>  
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ); 
    }
}

export { Terms };