import React from 'react';
import styled from 'styled-components';

const Container = styled('div')<{ bgColor: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${({ bgColor}) => bgColor};
`;

const ContentContainer = styled.div`
    max-width: 600px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
`;

interface SectionProps {
    bgColor: string;
}

const Section: React.FC<SectionProps> = ({ bgColor, children }) => {
    return(
        <Container bgColor={bgColor}>
            <ContentContainer>
                {children}
            </ContentContainer>
        </Container>
    );
};

export { Section };
